import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle"
export default class extends Controller {
  static targets = [ "menu" ]

  open() {
    Array.from(this.menuTargets).forEach((menu) => {
      menu.classList.remove('hidden')
    })
  }

  close() {
    Array.from(this.menuTargets).forEach((menu) => {
      menu.classList.add('hidden')
    })
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="expirable"
export default class extends Controller {
  static values = { timeout: Number }

  connect() {
    setTimeout(this.remove.bind(this), this.timeoutValue || 5000);
  }

  remove() {
    this.element.parentElement.removeChild(this.element);
  }
}
